<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
import { fetchZbData } from "@/api/newapiUrl.js";
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  watch: {},
  created() {
    fetchZbData().then((res) => {
      localStorage.setItem("fetUrl", res.data);
    });
    // process.env.VUE_APP_API_CHAT_URL = '123'
    console.log(
      "process.env.VUE_APP_API_CHAT_URL",
      process.env.VUE_APP_API_CHAT_URL
    );

    this.$ipc.invoke(ipcApiRoute.setConfig, {
      token: localStorage.getItem("token"),
      localhosts: this.$baseIP,
    });
    // this.$ipc.invoke(ipcApiRoute.openDoneLisenter); //创建服务监听
    localStorage.setItem("npzStatus", "");
  },
  methods: {
    openServe() {
      try {
        this.$ipc.invoke(ipcApiRoute.openServer, "srs-start.exe");
        setTimeout(() => {
          this.$ipc.invoke(ipcApiRoute.openServer, "start.exe");
        }, 3500);
      } catch (error) {
        console.error("Error saving file:", error);
      }
    },
  },
};
</script>
<style lang="less">
@import url(./../public/css/common.less);
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 1760px;
  height: 990px;
  font-size: 15px;
  overflow: hidden;
}
</style>
